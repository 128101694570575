/* Customize your main colors in :root variables */

:root {
  --main-background-color: #343A50;
  --card-background-color: #804980;
  --countdown-background-color: #b7eafc;
  --main-text-color:#F7F6F4;
  --title-text-color:#b7eafc;
}

/* Load a smaller image for smaller screens */
@media only screen and (max-width:500px) {
  body{
    background-size: cover !important;
  }
}
#root {
  width: 100% !important;
  height: 100% !important;
}
main {
  width: 100% !important;
  height: 100% !important;
}
main > div {
  margin: auto;
}
html {
  width: 100% !important;
  height: 100% !important;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  scrollbar-width: none; /* for firefox */
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.v-navigation-drawer__content {
  scrollbar-width: none; /* for firefox */
}

::-webkit-scrollbar {
    display: none!important;
}

body{
/*  background-color:var(--main-background-color);*/
  background: #004d9b url("img/cryptbg.png") no-repeat top center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: -apple-system, 'Hamberger-Bold', 'Helvetica Neue', sans-serif;
  font-size: 1.1em;
  width: 100% !important;
  height: 95% !important;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@font-face {
	font-family: 'Hamberger-Bold';
	src: url('./fonts/Hamberger-Bold.ttf') format('truetype');
	font-display: auto;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}

.MuiPaper-root {
  background: none !important;
}

.price {
  display: inline-block;
  padding: 5px 15px;
  height: 50px;
  font-size: 3rem;
  border-radius: 25px;
  background-color: rgba(0,0,0,0.5);
}
