body {
  margin: 0;
  font-family: -apple-system, 'Hamberger-Bold', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #FFFFFF;
}

@font-face {
	font-family: 'Hamberger-Bold';
	src: url('./fonts/Hamberger-Bold.ttf') format('truetype');
	font-display: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
